.app {
    // color: red !important;
}
.instructions-container{
    margin-top:100px;
}
.instructions {
    text-align: left;
    line-height: 1.3em
}
.proper-noun{
    font-weight: bold;
    font-family: monospace;
    text-transform: uppercase;
    font-size: 1.1em;
}