$color-light-text: #feefec;
$color-background: #4fc0ff;
$color-selection: #ea96ff;

.app {
  background-color: $color-background;
  color: $color-light-text;
  font-weight: bold;
  font-size: 1.2em;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: scroll;
  text-align: center;

  ::-moz-selection {
    /* Code for Firefox */
    background: $color-selection;
  }
  ::selection {
    background: $color-selection;
  }

  $banner-height: 27px;
  $banner-padding: 3px;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $banner-height;
    background-color: $color-light-text;
    color: $color-background;
    padding: $banner-padding;
  }
  .banner-offset {
    position: absolute;
    top: $banner-height + ($banner-padding * 2);
    bottom: 0;
    left: 0;
    right: 0;
  }
  .input {
    width: 100%;
    input {
      font-weight: bold;
      color: $color-light-text;
      font-size: 1.5em;
      width: 100%;
    }
  }
  .button {
    margin-top: 10px;
    width: 100%;
    background-color: $color-light-text;
  }
  .view-container {
    padding: 20px;
  }
  .link-button {
    color: $color-light-text;
    padding: 10px;
    display: block;
    font-size: 1.2em;
    text-decoration: none;
    border-radius: 5px;
    border: 4px solid $color-light-text;
    margin-bottom: 10px;
  }
}
