@import "playerApp";
@import "canary/_index";

$color-error: #efdb46;

.app {
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

.inline-text {
  display: inline-block;
  & + & {
    margin-left: 0.5ch;
  }
}

.form-error {
  color: $color-error;
}

.login-input input {
  text-transform: uppercase;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  .option {
    flex: 33%;
    max-width: 33%;
  }
}
.form-error {
  padding: 5px 5px 10px;
}
p {
  font-weight: 200;
}

.width-limit,
.limit-width {
  max-width: 700px;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}
