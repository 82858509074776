@import "./constants";
@import "./roundTracker";

.canary {
  font-family: Helvetica, Arial, sans-serif;
  color: $color-dark-shade;
  font-weight: bold;
  text-align: center;
  
  .hidden {
    display: none;
  }

  .form-error {
    color: $color-accent-shade;
  }

  .wipe-offset {
    position: absolute;
    top: 0;
    bottom: $wipe-height + $wipe-border;
    left: 0;
    right: 0;
    overflow: scroll;
    background-color: $color-light-shade;
  }
  .wipe {
    position: absolute;
    height: $wipe-height;
    background-color: $color-dark-shade;
    border-top: $wipe-border solid $color-mid-shade;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .author {
    background-color: $color-mid-shade;
    color: $color-light-text;
    position: absolute;
    top: -19px;
    left: -40px;
    transform: rotate(-24deg);
    padding: 10px;
    border-radius: $border-radius;
    min-width: 100px;
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .prompt {
    background-color: $color-dark-shade;
    color: $color-light-text;
    margin: 5px;
    border-radius: $border-radius;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    transition: all 0.2s;

    .prompt-author {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 28px; // this accounts for emoji heights
      background-color: $color-mid-shade;
      border-radius: $border-radius $border-radius 0 0;
    }
    .prompt-body {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 42px;
    }
    .voter-container {
      min-height: 33px;
    }
  }

  .user-input-base {
    font-weight: bold;
    color: $color-dark-text;
    resize: vertical;
    background-color: $color-light-text;
    border: none;
    margin-bottom: 10px;
    &:focus {
      outline: $color-mid-shade auto 5px;
    }
    &::placeholder {
      color: $color-mid-shade;
    }
  }
  .user-input {
    @extend .user-input-base;
    width: 94%;
    padding: 3%;
    font-size: $font-size;
  }

  .user-input-inline {
    @extend .user-input-base;
    min-width: 100px;
    height:30px;
    display: inline-block;
    text-align: left;
  }

  .button {
    background-color: $color-dark-shade;
    width: 100%;
    color: $color-light-text;
    font-weight: bold;
    font-size: $font-size;
    &:hover {
      background-color: $color-dark-text;
    }
    margin-bottom: 2 * $base-margin;
  }

  .view-container {
    padding: 20px;
    margin: 20px;
  }

  &.room-view {
    font-size: 1.1em;
    .vote-button {
      min-height: 80px;
    }
    .responses-container {
      display: flex;
      margin:0 50px;
      .response-container {
        padding: 5px;
        width: 50%;
        .response {
          position: relative;

          .vote {
            text-transform: uppercase;
          }
        }
      }
    }
    .countview-view {
      text-align: center;
      .time {
        font-weight: bold;
      }
    }
  }

  &.player-view {
    position: a;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    .view-container {
      padding: 20px;
      max-width: 500px;
      margin: 20px auto;
    }
    .vote-button {
      cursor: pointer;
    }
  }

  .prompt-container {
    display: inline-block;
    position: relative;
    .author {
      top: 3px;
      left: -60px;
    }
  }

  .user-text {
    text-transform: lowercase;
  }
  .system-text {
    text-transform: uppercase;
  }

  .voter {
    background-color: $color-accent-shade;
    color: $color-light-text;
    padding: 1px 10px;
    margin: 5px;
    border-radius: $border-radius;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .system-text;
    &.bonus {
      background: $color-dark-shade;
    }
  }

  .voter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &.stack {
      flex-direction: column;
      align-items: center;
    }
    .voter {
      flex: inherit;
    }
  }

  .vote-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    background-color: $color-dark-shade;
    font-size: $font-size + 0.2em;
    text-transform: lowercase;
    padding: 10px;
    color: $color-light-text;
    border: 6px solid $color-mid-shade;
    margin: 5px;
    text-align: center;
  }

  .remaining-players-container {
    margin-top: 20px;
    .option {
      flex: inherit;
      justify-content: center;
    }

    .remaining-player {
      @extend .voter;
    }
  }

  .player-score-container {
    position: absolute;
    left: 50px;
    right: 50px;
    display: flex;
    top: 120px;
    bottom: 50px;

    .player-score {
      transition: all 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0px 5px 5px;
      flex: 1;
      color: $color-light-text;
      font-size: 1.1em;

      position: absolute;
      top: 0;
      bottom: 0;

      .player-score-header {
        padding: 5px;
        margin-bottom: 5px;
        color: $color-accent-shade;
        position: relative;
        top: 10px;
      }

      .player-name {
        word-break: break-word;
      }

      .score {
        padding: 5px 0;
        margin: 0 5px;
        min-height: 30px;
        font-size: 1.3em;
        background: $color-mid-shade;
        transition: all 0.5s;
      }
    }
    &.has-many {
      .player-score-header{
        left: 30%;
        transform: rotate(-35deg);
        transform-origin: left;
        width: 303px;
        text-align: left;  
      }

      .score {
        font-size: 1.1em;
      }

    }
    
  }
}
.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: top;
  z-index: 1000;
}
.center-div {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
}

.big-center-text {
  font-size: 300px;
  line-height: 0.8em;
  filter: drop-shadow(2px 4px 6px black);
  .smaller-text {
    font-size: 0.6em;
    line-height: 0.9em;
  }
  @extend .center-div;
}

.winner-view .big-center-text {
  font-size: 100px;
  line-height: 1em;
}
