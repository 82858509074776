@import "./constants";
$size: 90px;
$offset-right: 30px;
$offset-bottom: 20px;
$circle-offset: 7px;
$z-index: 10;

.round-circle {
  border-radius: 50%;
  color: $color-light-text;

  width: $size;
  height: $size;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: $offset-right;
  bottom: $offset-bottom;
  font-size: 18px;

  &.circle-1 {
    right: $offset-right + $circle-offset;
    background-color: $color-accent-shade;
    z-index: $z-index;
  }

  &.circle-2 {
    right: $offset-right;
    background-color: $color-dark-shade;
    z-index: $z-index - 1;
  }

  &.circle-3 {
    right: $offset-right - $circle-offset;
    background-color: $color-mid-shade;
    z-index: $z-index - 2;
  }

  &.circle-active {
    z-index: $z-index + 1;
  }
  &.circle-past {
    z-index: $z-index;
  }

  .round-tracker-title {
    padding-top: 7px;
    text-transform: uppercase;
  }
  .round-tracker-number {
    font-size: 1.4em;
  }
}
