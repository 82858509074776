.remaining-players-container {
    display: flex;
    margin-bottom: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.remaining-player {
    transition: opacity 300ms;
    width: 200px;
    opacity: 1;
}
.remaining-player-exit-active {
    opacity: 0;
}
.canary .wipe-offset {
    // background-color: blue !important;
}
