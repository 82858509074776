$color-accent-shade: #4fc0ff;
$color-mid-shade: #ffa83f;
$color-dark-shade: #ff6949;
$color-light-shade: #ffd85e;
$color-light-text: #feefec;
$color-dark-text: #de6c54;
$border-radius: 3px;
$font-size: 1.2em;
$wipe-height: 0px;
$wipe-border: 0px;
$base-margin: 3px;


.mr-2 {
    margin-right: 2 * $base-margin !important;
}